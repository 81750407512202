import 'regenerator-runtime/runtime';

const loadAdminScript = async () => {
  await import('./admin/auth/signin');
  await import('./admin/board/list');
  await import('./admin/board/articles');
  await import('./admin/board/write');
  await import('./admin/board/read');
  await import('./admin/materials/regist');
  await import('./admin/materials/regist-excel');
  await import('./admin/materials/list');
  await import('./admin/example/live-scene-regist');
  await import('./admin/example/live-scene-list');
  await import('./admin/example/regist');
  await import('./admin/example/list');
  await import('./admin/task/manager/list');
  await import('./admin/task/manager/modify');
  await import('./admin/product/list');
  await import('./admin/product/modify');
  await import('./admin/product/price-modify');
  await import('./admin/reading-contents/category');
  await import('./admin/reading-contents/content-list');
  await import('./admin/reading-contents/content-edit');
}

const loadCsScript = async () => {
  await import('./cs/index');
  await import('./cs/signin');
  await import('./cs/common');
  await import('./cs/application/list');
  await import('./cs/application/detail-common');
  await import('./cs/application/detail-apply-info');
  await import('./cs/application/detail-estimation');
  await import('./cs/application/detail-payment');
  await import('./cs/application/detail-materials');
  await import('./cs/application/detail-task-manager-assign');
  await import('./cs/application/detail-material-ordering');
  await import('./cs/application/detail-alimtalk');
  await import('./cs/application/detail-custom-estimation');
  await import('./cs/calendar');
}

const loadMypageScript = async () => {
  await import('./mypage/common-script');
  await import('./mypage/index');
  await import('./mypage/signin');
  await import('./mypage/my-estimation');
  await import('./mypage/contract-progess');
  await import('./mypage/contract-confirm');
  await import('./mypage/materials-preview');
  await import('./mypage/materials-select');
  await import('./mypage/construction-schedule');
  await import('./mypage/balance-payment');
  await import('./mypage/defect-repair');
  await import('./mypage/construction-location-info');
}

const loadFrontScript = async () => {
  await import('./front/home');
  await import('./front/common/index');
  await import('./front/large-scale-inquiry');
  await import('./front/estimation/floor');
  await import('./front/estimation/papering');
  await import('./front/estimation/molding');
  await import('./front/estimation/window');
  await import('./front/estimation/sink');
  await import('./front/estimation/light');
  await import('./front/estimation/firm');
  await import('./front/estimation/door');
  await import('./front/estimation/bathroom');
  await import('./front/estimation/common');
  await import('./front/estimation/packages/common');
  await import('./front/estimation/packages/monthlyrent');
  await import('./front/materials/list');
  await import('./front/materials/detail');
  await import('./front/example/list');
  await import('./front/example/detail');
  await import('./front/example/scene-list');
  await import('./front/megazine/list');
  await import('./front/megazine/detail');
  await import('./front/service-center/faq');
  await import('./front/reading-contents/list');
  await import('./front/etc-payment');
}

(async () => {

  // path를 확인하여 어드민/cs/프론트 구분
  let regExp = new RegExp("^\/(admin|cs|mypage)\/?");
  let result = regExp.exec(window.location.pathname);
  if (window.location.pathname == '/' || !result?.[1]) {
    await loadFrontScript();
    return;
  }
  // 스크립트 동적로드
  switch (result[1]) {
    case 'admin':
      await loadAdminScript();
      break;
    case 'cs':
      await loadCsScript();
      break;
    case 'mypage':
      await loadMypageScript();
      break;
    default:
      await loadFrontScript();
      break;
  }
})();